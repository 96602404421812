<template>
  <el-dialog
    :title="dataForm.id ? '编辑' : '新增' + '围餐套餐详情'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="fullScreenDialog missionPart"
    append-to-body
  >
    <el-form :model="dataForm" ref="dataForm" label-width="80px">
      <div class="tablePart">
        <el-form-item label="套餐名" prop="name">
          <el-input v-model="dataForm.name" clearable disabled />
        </el-form-item>
        <el-form-item label="原价" prop="price">
          <el-input-number v-model="dataForm.price" :min="0" disabled />
        </el-form-item>
        <el-form-item label="折扣价" prop="discountPrice">
          <el-input-number v-model="dataForm.discountPrice" :min="0" disabled />
        </el-form-item>
      </div>
      <div class="doubleTable">
        <div class="topAddBtnList">
          <el-button @click="addCateName" type="primary" size="mini"
            >增加餐品目录</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="addNewGoods"
            :disabled="!(dataListSelections.length === 1)"
            >增加餐品对应详情</el-button
          >
        </div>
        <div class="tableWrap">
          <div class="leftTable">
            <el-table
              height="calc(100vh - 300px)"
              :data="dataList"
              ref="dataList"
              border
              @selection-change="$selectionChange($event, 'dataList')"
              @row-click="$clickRow($event, 'dataList', getDetailList($event))"
              @select="$select(arguments, 'dataList')"
              @select-all="$clearSelection('dataList')"
            >
              <el-table-column
                type="selection"
                header-align="center"
                align="center"
                width="50"
              />
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="围餐类别名"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name" clearable />
                </template>
              </el-table-column>
              <el-table-column
                prop="isUse"
                header-align="center"
                align="center"
                label="单一选择/多选"
              >
                <template slot-scope="scope">
                  <el-select v-model="scope.row.type">
                    <el-option :value="0" label="单独选择" />
                    <el-option :value="1" label="多选选择" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                label="最高选中数"
              >
                <template slot-scope="scope">
                  <el-input-number
                    v-if="scope.row.type === 1"
                    v-model="scope.row.limitNum"
                    :min="0"
                    disabled
                  />
                  <span v-else>1</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="rightTable">
            <el-table
              :data="detailList"
              height="calc(100vh - 300px)"
              ref="detailList"
              border
              stripe
              v-loading="detailListLoading"
              @row-click="$clickRow($event, 'detailList')"
              @selection-change="$selectionChange($event, 'detailList')"
              style="width: 100%"
            >
              <el-table-column
                prop="goodsId"
                header-align="center"
                align="center"
                label="餐品名"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.goodsId"
                    placeholder="请选择餐品"
                    @change="goodsChoose($event, scope.$index)"
                  >
                    <el-option :value="0" label="商品1" />
                    <el-option :value="1" label="商品2" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="mobile"
                header-align="center"
                align="center"
                label="价格"
              />
              <el-table-column
                prop="isUse"
                header-align="center"
                align="center"
                label="是否使用"
              >
                <template slot-scope="scope">
                  <el-tag size="mini" v-if="scope.row.isUse === 1"
                    >使用中</el-tag
                  >
                  <el-tag size="mini" v-else type="danger">禁用</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="isUse"
                header-align="center"
                align="center"
                label="排序号"
              />
              <el-table-column
                prop="createTime"
                header-align="center"
                align="center"
                label="创建时间"
              />
            </el-table>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { threeListMixin, dialogMixin, normal } from '@/mixins';
export default {
  mixins: [threeListMixin, dialogMixin, normal],
  data() {
    return {
      dataForm: {
        id: null,
        name: null, // 套餐名
        isUse: 1,
        price: 0, // 原价
        discountPrice: 0, // 折扣价
        useDate: null, // 可用日期
        reminder: null, // 限制提醒
        useRules: null, // 使用规则
      },
    };
  },
  methods: {
    init(id) {
      this.$init({
        before: () => {
          this.dataForm.id = id;
        },
        url: id ? `/cm/shoppingrecord/findDetail/${this.dataForm.id}` : null,
        after: (data) => {
          if (data?.code === 0) {
            this.dataForm = { ...data.shoppingDetail };
            this.editor.txt.html(data.shoppingDetail.useRules);
          }
        },
      });
    },
    handleCancel() {
      this.visible = false;
    },
    dataFormSubmit() {
      this.visible = false;
    },
    // 获取数据列表
    getDetailList(row) {
      this.$api({
        url: '/delivery/deliverytask/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          freezerName: this.searchForm.freezerName,
          status: this.searchForm.status,
        },
        after: (data) => {
          if (data && data.code === 0) {
            if (data.page && data.page.records?.length) {
              this.detailList = data.page.records;
              this.detailListCount = data.page.total;
            } else {
              this.detailList = [];
              this.detailListCount = 0;
            }
          } else {
            this.detailList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    // 新增围餐类别
    addCateName() {
      this.dataList.push({
        timeId: +new Date(),
        name: null,
        type: 0, // type:0是单选， 1是多选
        limitNum: 10, // 最高选多少个
        goodsList: [],
      });
    },
    //
    addNewGoods() {
      if (this.dataListSelections.length === 1) {
        this.detailList = this.dataListSelections[0].goodsList;
      } else {
        this.$message.error('只能选择一个');
        this.detailList = [];
      }
    },
    goodsChoose(data, index) {
      let getData = this.goodsList.filter((i) => i.id === data);
      this.detailListSelections.filter((i, lastIndex) => {
        if (lastIndex === index) {
          i = { ...getData[0] };
        }
      });
    },
  },
};
</script>
